/** @flow
 * StratoDem Analytics : join-the-team
 * Principal Author(s) : Michael Clawar
 * Secondary Author(s) :
 * Description :
 *
 *  (c) 2016- StratoDem Analytics, LLC
 *  All Rights Reserved
 */

import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';

import {Layout, SEO} from '../../components';

import './join-the-team.scss';

export default class Index extends React.Component {
  render() {
    return (
      <Layout>
        <SEO
          title="Join the team"
          keywords={['stratodem analytics']}
          description="Join the team"
        />
        <div className="sd-section join-the-team">
          <div className="inner">
            <h2>Join the team</h2>
            <p>
              StratoDem Analytics is a data science firm building predictive models on massive
              data for better US local-market knowledge. We help some of the
              largest private equity funds and real estate organizations in the US make better
              portfolio-level and business decisions based on a fundamentally better understanding
              of local-market economic risk and demographic outlooks... and we want to work with
              you:
            </p>

            <hr />
            <h3>Data Scientist &ndash; Real Estate</h3>
            <p>
              StratoDem Analytics is currently seeking a Data Scientist who will help
              design and build data analytics pipelines, scalable Python-based APIs, and new
              data products for the real estate field. This <strong>remote position</strong> is for someone seeking to
              work in a remote environment to develop and improve real estate analysis using
              statistical and machine learning methods.
            </p>
            <p><a href="/join-the-team/data-scientist">Learn more &rarr;</a></p>

            <hr />
            <h3>Web Development Intern</h3>
            <p>
              StratoDem Analytics is currently seeking Web Development Interns who will help
              design and build data analytics applications for the real estate field. This
              position is for someone seeking to work in a remote environment to design and
              implement analytics-oriented web applications.
            </p>
            <p><a href="/join-the-team/web-development-intern">Learn more &rarr;</a></p>
          </div>
        </div>
      </Layout>);
  }
}
